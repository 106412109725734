import type { NewsletterCtaFragment } from '~/model/api.ts'
import { Button } from '@rouvydev/web-components/basics'
import { Input } from '~/components/input.tsx'
import { useFetcher } from '@remix-run/react'
import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { z } from 'zod'
import type { action } from '~/routes/resources.newsletter.ts'
import { ErrorList } from '~/components/errors-list.tsx'
import { useTranslation } from 'react-i18next'
import { Alert } from '~/components/alert.tsx'
import { EmailSchema } from '~/utils/schema.ts'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Cols } from '~/components/cols.tsx'
import { trackEvent } from '~/utils/gtm.client.ts'
import { cn } from '@rouvydev/web-components/utils'
import { Image } from '~/components/image.tsx'
import { Article } from '~/components/article.tsx'
import { Markdown } from '~/components/markdown.tsx'

type TransformedNewsletterCta = {
  transformedHint: RenderableTreeNodes
} & NewsletterCtaFragment

export function prepareNewsletterCtaProps(data: NewsletterCtaFragment) {
  const transformedObject = data as TransformedNewsletterCta
  return {
    id: data.id,
    description: data.description,
    hint: transformedObject.transformedHint,
    campaignId: data.campaignId,
    title: data.ctaTitle,
    buttonLabel: data.newsletterCtaButtonLabel,
    visual: data.visual,
    anchor: data.newsletterCtaAnchor,
  }
}

export const NewsletterSchema = z.object({
  email: EmailSchema,
  campaignId: z.string(),
})

type Props = ReturnType<typeof prepareNewsletterCtaProps>

export function NewsletterCta(props: Props) {
  const { t } = useTranslation()
  const { title, description, buttonLabel, visual, campaignId, hint } = props
  const imageUrl = visual?.data?.attributes?.url
  const fetcher = useFetcher<typeof action>()

  const [form, fields] = useForm({
    id: 'newletter-form',
    constraint: getFieldsetConstraint(NewsletterSchema),
    defaultValue: { email: '' },
    lastSubmission: fetcher.data?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: NewsletterSchema })
    },
    shouldRevalidate: 'onBlur',
    onSubmit(_, { formData }) {
      trackEvent('email_captured', {
        campaignId: campaignId,
        formType: 'newsletter',
        capturedEmail: formData.get('email'),
      })
    },
  })

  const busy = fetcher.state !== 'idle'

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20 md:py-10">
        <div className="container">
          <Cols size={imageUrl ? '12' : '6'}>
            <div
              className={cn(
                'flex gap-10 md:gap-8',
                imageUrl ? 'flex-col md:flex-row' : 'flex-col',
              )}
            >
              <div
                className={cn(
                  'my-auto flex flex-col gap-4 md:gap-6',
                  imageUrl
                    ? 'basis-5/12 [&>*]:text-center md:[&>*]:text-left'
                    : 'w-full [&>*]:text-center',
                )}
              >
                <h3 className="font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-left xl:text-display-48">
                  {title}
                </h3>
                <p className="block-description xl:text-left">{description}</p>
                <fetcher.Form
                  action="/resources/newsletter"
                  {...form.props}
                  method="POST"
                >
                  {fetcher.data?.status === 'success' && (
                    <Alert
                      closeable={false}
                      message={t('newsletter_subscribed')}
                    />
                  )}
                  {fetcher.data?.status === 'error' && (
                    <Alert
                      variant="warning"
                      closeable={false}
                      message={t('newsletter_subscription_error')}
                    />
                  )}
                  <input type="hidden" name="campaignId" value={campaignId} />
                  <div className="flex flex-col items-center gap-4 xl:flex-row">
                    <div className="w-full">
                      <Input
                        {...conform.input(fields.email)}
                        className="w-full"
                        placeholder={t('enter_email')}
                      />
                    </div>
                    <Button
                      type="submit"
                      className="w-full min-w-max justify-center xl:w-auto"
                      variant="primary"
                      disabled={busy}
                    >
                      {busy ? t('loading') : buttonLabel}
                    </Button>
                  </div>
                  {fields.email.errors?.length && (
                    <ErrorList
                      errors={
                        fields.email.errors?.length
                          ? fields.email.errors
                          : ['\u00A0']
                      }
                    />
                  )}
                </fetcher.Form>
                {hint && (
                  <Article className="font-medium leading-normal prose-p:my-0 prose-p:text-[10px] md:prose-p:text-body-12">
                    <Markdown content={hint} />
                  </Article>
                )}
              </div>
              {visual?.data?.attributes?.url && (
                <div className="flex h-full basis-7/12 justify-center max-md:order-first">
                  <Image
                    src={visual?.data?.attributes?.url}
                    alt={visual?.data?.attributes?.alternativeText ?? ''}
                    width={visual?.data?.attributes?.width ?? 0}
                    height={visual?.data?.attributes?.height ?? 0}
                    formats={visual.data?.attributes?.formats}
                    className="h-full max-h-[500px] w-full object-cover md:max-h-[1000px]"
                  />
                </div>
              )}
            </div>
          </Cols>
        </div>
      </div>
    </div>
  )
}
